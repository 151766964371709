import React from "react";
import UserProfile from "../../../app/partials/layout/UserProfile/UserProfile";
import LanguageSelector from "app/partials/layout/LanguageSelector";
import APPLICATION_VIEWS from "../../../app/constants/APPLICATION_VIEWS";
import {FormattedMessage, injectIntl} from "react-intl";
import clsx from "clsx";
import styles from "../../../app/partials/layout/UserProfile/index.module.scss";
import ButtonTooltip from "../../../app/partials/components/ButtonTooltip";
import connect from "react-redux/es/connect/connect";
import {loginAsUser, updateUserData} from "../../../app/crud/user.crud";
import UserChangeCountry from "../../../app/pages/common/UserProfile/UserOptions/UserChangeCountry";

class Topbar extends React.Component {
  render() {
    const { user, loginAsUser, intl, updateUserData } = this.props;
    const applicationView = user && user.currentApplicationView;

    return (
      <div className="kt-header__topbar">
        {user?.loggedInAs && (
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: 25 }}
          >
            <ButtonTooltip
              tPlacement="bottom"
              tOverlay={
                <FormattedMessage
                  id="GENERAL.PHRASE.LOGGED_IN_AS"
                  values={{ user: <strong>{user?.email}</strong>, br: <br /> }}
                />
              }
              onClick={() => loginAsUser(null)}
            >
              <span
                className={clsx(styles.badgePro, styles.trial)}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  fontSize: "1.2rem"
                }}
              >
                <FormattedMessage id="GENERAL.PHRASE.ADMIN_LOGIN_AS_ACTIVE" />
              </span>
            </ButtonTooltip>
          </div>
        )}
        {applicationView === APPLICATION_VIEWS.INDUSTRIAL &&
        <UserChangeCountry
          intl={intl}
          toSelect="country"
          user={user}
          onSave={updateUserData}
          btnSelect
        />
        }
        <UserProfile user={user} showAvatar={false} showHi={true} showBadge={true} />
        <LanguageSelector />
      </div>
    );
  }
}

const mapDispatchToProps = {
  loginAsUser: (id) => loginAsUser(id),
  updateUserData: (userData, avatar, companyLogo, pdfCover, redirect) =>
    updateUserData(userData, avatar, companyLogo, pdfCover, redirect),
};
export default injectIntl(connect(null, mapDispatchToProps)(Topbar));
