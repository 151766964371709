import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import {BUILDING_TYPES} from "app/constants";
import {toDateFormat, toNumberFormat, snakeToCamelCase} from "app/utils/helpers";
import RobotoLight from "../../../../../assets/fonts/roboto-light-webfont.ttf";
import RobotoBold from "../../../../../assets/fonts/roboto-bold-webfont.ttf";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {UNIT_TYPES} from "app/constants";

// Register fonts that support Polish characters
Font.register({
  family: 'Roboto',
  formar: "truetype",
  fonts: [
    { src: RobotoLight, fontWeight: 'light' },
    { src: RobotoBold, fontWeight: 'bold' },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 36,
    paddingTop: 46,
    paddingBottom: 46,
    fontFamily: 'Roboto',
    fontSize: 8,
    width: 595.28, // A4 width in points (72 points per inch)
  },
  headerSection: {
    flexDirection: 'row',
     marginBottom: 5, // Changed from 10 to 5
    justifyContent: 'space-between', // This will create space between columns
  },
  buildingInfoColumn: {
    width: '60%', // 65% width for the left column
  },
  buildingInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buildingImage: {
    width: '32%', // 25% of the page width (38.46% of 65% is 25% of the total page width)
    aspectRatio: 1, // This ensures a 1:1 aspect ratio
    objectFit: 'cover',
    borderRadius: 2, // Added border radius of 2
  },
  buildingInfo: {
    width: '64.54%', // Remaining width in the buildingInfoColumn (65% - 25% = 40% of page width, which is 58.54% of the buildingInfoColumn)
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buildingName: {
    fontSize: 16, // Adjust if needed for the longer name
    fontWeight: 'bold',
    marginBottom: 2,
  },
  address: {
    fontSize: 8,
    color: '#2d2d2d',
  },
  headerSectionTable: {
    display: 'flex',
    width: 'auto',
  },
  headerSectionTableRow: {
    flexDirection: 'row',
    height: 12,
  },
  headerSectionTableCol: {
    width: '50%',
  },
  headerSectionTableCell: {
    fontSize: 8,
    textAlign: 'left',
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  headerSectionTableCellRight: {
    fontSize: 8,
    textAlign: 'left', // Changed from 'right' to 'left'
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
    // Removed fontWeight: 'bold'
  },
  ownerColumn: {
    width: '35%', // 30% width for the right column
  },
  ownerBox: {
    width: '100%', // Make it full width of its parent
    backgroundColor: '#FBFBFB',
    borderRadius: 3,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center', // This will vertically center the content
    justifyContent: 'space-between',
     marginBottom: 5, // Changed from 10 to 5
  },
  ownerInfo: {
    flex: 1,
    justifyContent: 'center', // This will vertically center the text
  },
  ownerLabel: {
    fontSize: 8,
    marginBottom: 2, // Reduced margin to bring text closer together
    fontWeight: 'bold',
  },
  ownerName: {
    fontSize: 8,
  },
  ownerLogoContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 2, // Changed from 1 to 2
    borderRadius: 1,
    width: 'auto',
    height: 20
  },
  ownerLogo: {
    width: 'auto',  // Adjust as needed
    height: 18,  // This will maintain the aspect ratio
  },
  footer: {
    position: 'absolute',
    bottom: 18,
    left: 36,
    right: 36,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
     marginBottom: 5, // Changed from 10 to 5
  },
  footerText: {
    fontSize: 6.4, // Reduced by 20% from 8 to 6.4
    color: '#666', // Ensure this color contrasts with the background
    marginRight: 5, // Add some space between text and logo
  },
  footerLogo: {
    width: 40, // Adjust as needed
    height: 'auto',
  },
  reportDate: {
    fontSize: 8,
    color: '#A2A5B8',
    marginTop: 4
  },
  reportDateBold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 15, // Changed from 20 to 15
  },
  leasedTermsSection: {
     marginBottom: 5, // Changed from 10 to 5
  },
  tablesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leasedTermsTable: {
    width: '48%',
  },
  leasedTermsTableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    height: 20,
  },
  availableUnitsSection: {
     marginBottom: 5, // Changed from 10 to 5
    width: '100%',
  },
  unitRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    minHeight: 24,
  },
  unitHeaderRow: {
    backgroundColor: '#f9f9f9',
    minHeight: 24, // 20% smaller than the regular row (30 * 0.8 = 24)
  },
  unitCell: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  unitCellRight: {
    fontSize: 8,
    textAlign: 'right',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  unitHeaderCell: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  unitHeaderCellRight: {
    fontSize: 8,
    textAlign: 'right',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  pageHeader: {
    position: 'absolute',
    top: 20, // Increased from 10 to 20 (50% lower)
    left: 36,
    right: 36,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5,
  },
  pageHeaderImage: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  pageHeaderContent: {
    flex: 1,
  },
  pageHeaderBuildingName: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  pageHeaderAddress: {
    fontSize: 6,
    color: '#666',
  },
  leasedTermsTableCol: {
    width: '50%',
  },
  leasedTermsTableCell: {
    fontSize: 8,
    textAlign: 'left',
    padding: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  leasedTermsTableCellRight: {
    fontSize: 8,
    textAlign: 'right', // Align to right
    fontWeight: 'bold',
    padding: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  sectionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
     marginBottom: 5, // Changed from 10 to 5
  },
  sectionAmenities: {    
    width: '60%',  // Set width to 48% for both sections
  },
  sectionAmenitiesFullWidth: {
    width: '100%'
  },
  sectionCertificates: {
    width: '36%',  // Set width to 48% for both sections
  },
  fullWidthSection: {
     marginBottom: 5, // Changed from 10 to 5
    width: '100%',  // Set width to 100% for Market and Competition Comparison sections
  },
  amenitiesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
     marginBottom: 5, // Changed from 10 to 5
  },
  amenityItem: {
    fontSize: 8,
    borderColor: '#E6E6E6',
    borderWidth: 1,
    borderRadius: 10, // This is equivalent to 25% for most cases
    paddingVertical: 2,
    paddingHorizontal: 6,
    marginRight: 5,
    marginBottom: 5,
  },
  certificatesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap', // Allow wrapping of certificates
    marginTop: 5,
     marginBottom: 5, // Changed from 10 to 5
  },
  certificateItem: {
    marginRight: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#E6E6E6',
    borderRadius: 3,
    padding: 5,
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically
  },
  certificateLogo: {
    width: 40, // Set max width to 80px
    height: 'auto',
  },
  comparisonContainer: {
    width: '100%',  // Ensure table container takes full width
    // Removed marginBottom
  },
  marketComparisonRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    minHeight: 24,
    width: '100%',  // Ensure rows take full width
    flex: 1, // Make the row take all available space
    // Removed marginBottom
  },
  comparisonRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    minHeight: 30,
    width: '100%',  // Ensure rows take full width
    flex: 1, // Make the row take all available space
    // Removed marginBottom
  },
  comparisonHeaderRow: {
    backgroundColor: '#f9f9f9',
    minHeight: 24,
    // Removed marginBottom
  },
  comparisonCell: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonCellMiddle: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonCellRight: {
    fontSize: 8,
    textAlign: 'right',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonHeaderCell: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    width: '100%',
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonHeaderCellMiddle: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonHeaderCellRight: {
    fontSize: 8,
    textAlign: 'right',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  cellPlaceholder: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 0,
    width: 2,
  },
  comparisonCellFirstColumn: {
    fontSize: 8,
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
    width: '35%', // Set width to 35% for the first column
  },
  comparisonCellMiddleRight: {
    fontSize: 8,
    textAlign: 'right', // Align to right
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonHeaderCellMiddleRight: {
    fontSize: 8,
    textAlign: 'right', // Align to right
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  comparisonCellRightBold: {
    fontSize: 8,
    textAlign: 'right', // Align to right
    fontWeight: 'bold', // Make text bold
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  buildingNameContainer: {
    width: '100%', // Take 100% width of the page
    textAlign: 'left', // Align text to the left
    marginBottom: 10, // Add some margin below the building name
  },
});

// Helper function to replace EUR with €
const formatCurrency = (value) => {
  return value ? value.replace('EUR', ' €') : value;
};

// Helper function area format
const areaFormat = () => {
  const currentLang = localStorage.getItem("langManual");
  return currentLang === "pl" ? "m²" : "sqm";
}

// Create Document Component
function PropertyPdfDocument(props){
  const {intl, basic, owner, leaseTerms, amenities, certificates, competitors, availableUnits, leasingContacts, visibleMarketComparison, isComparisonEnable, isContactDataEnable} = props;
  // Function to translate strings
  const handleStringTranslation = (id, values) => {
    return intl.formatMessage({id: id}, values);
  };
  // Building data
  const buildingName = basic?.name || "-";
  const buildingType = basic?.buildingType;
  const streetName = basic?.addressStreet;
  const cityName = basic?.addressCity;
  const buildingAddress = [
    ...streetName ? [streetName] : [],
    ...cityName ? [cityName] : []
  ].join(", ");
  const buildingClass = basic?.buildingClass || "-";
  const completionYear = basic?.commissioningYear || "-";
  const grossArea = toNumberFormat(basic?.totalAreaBrutto) + " m²" || "0 m²";
  const netArea = toNumberFormat(basic?.totalAreaNetto) + " m²" || "0 m²";
  const updatedAt = basic?.updatedAtByUser;
  const buildingImage = basic?.mainPhotoThumbnail400x400	|| "/media/office-card-placeholder.jpg";
  const buildingTypeRender =
          buildingType === BUILDING_TYPES.OFFICE_BUILDING.id ? handleStringTranslation(BUILDING_TYPES.OFFICE_BUILDING.name) :
            buildingType === BUILDING_TYPES.APARTMENT_HOUSE.id ? handleStringTranslation(BUILDING_TYPES.APARTMENT_HOUSE.name) :
              buildingType === BUILDING_TYPES.WAREHOUSE.id ? handleStringTranslation(BUILDING_TYPES.WAREHOUSE.name) :
                buildingType === BUILDING_TYPES.SHOPPING_CENTER.id ? handleStringTranslation(BUILDING_TYPES.SHOPPING_CENTER.name) :
                  buildingType === BUILDING_TYPES.HQ.id ? handleStringTranslation(BUILDING_TYPES.HQ.name) :
                    buildingType === BUILDING_TYPES.TENEMENT_HOUSE.id ? handleStringTranslation(BUILDING_TYPES.TENEMENT_HOUSE.name) : handleStringTranslation(BUILDING_TYPES.OTHER.name);
  // Owner data
  const ownerName = owner?.ownerShortName || owner?.ownerName;
  const ownerLogo = owner?.ownerLogo;
  // Leasing contacts data
  const leasingCompanyName = leasingContacts?.landlordShortName || leasingContacts?.landlordName;
  const leasingPersonEmail = leasingContacts?.landlordContactPersons?.[0]?.email;
  const leasingPersonPhone = leasingContacts?.landlordContactPersons?.[0]?.phone;
  // Lease terms data
  const rentOfficeRange = [
    ...leaseTerms?.rentFromEur ? [toNumberFormat(leaseTerms?.rentFromEur)] : [],
    ...leaseTerms?.rentToEur ? [toNumberFormat(leaseTerms?.rentToEur)] : []
  ];
  const rentRetailRange = [
    ...leaseTerms?.retailRentPriceEur ? [toNumberFormat(leaseTerms?.retailRentPriceEur)] : [],
    ...leaseTerms?.retailRentPriceToEur ? [toNumberFormat(leaseTerms?.retailRentPriceToEur)] : []
  ];
  const rentOffice = (leaseTerms?.rentToNegotiate || rentOfficeRange?.length <= 0) ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : formatCurrency(rentOfficeRange.join(" - ") + "EUR");
  const rentRetail = (leaseTerms?.rentToNegotiate || rentRetailRange?.length <= 0) ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : formatCurrency(rentRetailRange.join(" - ") + "EUR");
  const parkingFactorArray = [
    ...leaseTerms?.parkingFactorNumber ? [leaseTerms?.parkingFactorNumber] : leaseTerms?.parkingFactorArea ? ["1"] : [],
    ...leaseTerms?.parkingFactorArea ? [leaseTerms?.parkingFactorArea] : []
  ];
  const parkingFactor = parkingFactorArray?.length > 0 ? parkingFactorArray.join("/") : "-";
  const parkingGround = leaseTerms?.parkingGroundNotExist ? handleStringTranslation("VOCABULARY.Not exist") :
    leaseTerms?.parkingGroundToNegotiate ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") :
    leaseTerms?.parkingGroundRentEur ? formatCurrency(Number(leaseTerms?.parkingGroundRentEur) + "EUR") : "-";
  const parkingUnderground = leaseTerms?.parkingUndergroundNotExist ? handleStringTranslation("VOCABULARY.Not exist") :
    leaseTerms?.parkingUndergroundToNegotiate ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") :
    leaseTerms?.parkingUndergroundRentEur ? formatCurrency(Number(leaseTerms?.parkingUndergroundRentEur) + "EUR") : "-";
  const serviceCharge = leaseTerms?.serviceChargesPln ? formatCurrency(Number(leaseTerms?.serviceChargesPln) + " PLN") : "-";
  const commonAreaFactor = leaseTerms?.commonAreaFactor ? Number(leaseTerms?.commonAreaFactor) + "%" : "-";
  const leasePeriod = leaseTerms?.minRentalYearsToNegotiate ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") :
                        leaseTerms?.minRentalYearsIndefinite ? handleStringTranslation("BUILDING.PHRASE.INDEFINITE") :
                          leaseTerms?.minRentalYears ?
                            handleStringTranslation("BUILDING.PHRASE.YEARS_VALUE", {years: leaseTerms?.minRentalYears, count: leaseTerms?.minRentalYears}) : "-";
  // Amenities
  const isAmenitiesActive = Object.keys(amenities)?.filter(amenity => amenities?.[amenity])?.length > 0;
  // Certificates
  const isCertificatesActive = () => {
    if(certificates) {
      const {breeamCertification, wellCertification, leedCertification, hqeCertification, dgnbCertification, withoutBarrierCertification} = certificates;
      const certs = [
        ...breeamCertification ? [true] : [],
        ...wellCertification ? [true] : [],
        ...leedCertification ? [true] : [],
        ...hqeCertification ? [true] : [],
        ...dgnbCertification ? [true] : [],
        ...withoutBarrierCertification ? [true] : []
      ];
      return certs?.length > 0;
    }
    return false;
  }
  const renderCertificates = () => {
    if(certificates) {
      const {breeamCertification, wellCertification, leedCertification, hqeCertification, dgnbCertification, withoutBarrierCertification} = certificates;
      const certs = [
        ...breeamCertification ? [{
          icon: <Image src="/media/certificates/breeam.png" alt="BREEAM" style={styles.certificateLogo}/>,
          title: "BREEAM"
        }] : [],
        ...wellCertification ? [{
          icon: <Image src="/media/certificates/well.png" alt="WELL" style={styles.certificateLogo}/>,
          title: "WELL"
        }] : [],
        ...leedCertification ? [{
          icon: <Image src="/media/certificates/leed.png" alt="LEED" style={styles.certificateLogo}/>,
          title: "LEED"
        }] : [],
        ...hqeCertification ? [{
          icon: <Image src="/media/certificates/hqe.png" alt="HQE" style={styles.certificateLogo}/>,
          title: "HQE"
        }] : [],
        ...dgnbCertification ? [{
          icon: <Image src="/media/certificates/dgnb.png" alt="DGNB" style={styles.certificateLogo}/>,
          title: "DGNB"
        }] : [],
        ...withoutBarrierCertification ? [{
          icon: <Image src="/media/certificates/obb.png" alt="Obiekt Bez Barier" style={styles.certificateLogo}/>,
          title: "OBB"
        }] : []
      ];
      
      return certs?.map((cert, index) => (
        <View key={index} style={styles.certificateItem}>
          {cert?.icon}
        </View>
      ));
    }
    return null;
  }
  // Market Comparison
  const handleCellValue = (value, index, key) => {
    const mandatoryValues = [
      "transactions_6m",
      "transactions_12m",
      "transactions_24m",
      "supply_boost_6m",
      "supply_boost_12m",
      "supply_boost_24m",
      "marketplace_leads_6m",
      "marketplace_leads_12m",
      "marketplace_leads_24m",
      "marketplace_views_6m",
      "marketplace_views_12m",
      "marketplace_views_24m",
      "marketplace_agent_interest_6m",
      "marketplace_agent_interest_12m",
      "marketplace_agent_interest_24m"
    ];
    const isMandatoryValue = mandatoryValues.includes(key);
    const finalValue = value ? value : (isMandatoryValue ? 0 : value);
    const cellToFixed = [1, 0, 1];
    const toFixedValue = isNaN(cellToFixed[index]) ? 0 : cellToFixed[index];
    const fixedValue = finalValue ? Number(finalValue).toFixed(toFixedValue) : finalValue;

    return toNumberFormat(fixedValue);
  }
  const handleCellKey = (key) => {
    if(key === "time_to_lease" || key === "area_available_future" || key.startsWith("marketplace_agent_interest")) {
      return handleStringTranslation(key);
    }
    else if(key.startsWith("marketplace_")) {
      return <Text>{handleStringTranslation(key, {finne: ""})} <Text style={{fontWeight: 700, color: "#00A6ED"}}>finne</Text></Text>;
    }
    return handleStringTranslation(key);
  }
  const handleCellSuffixValue = (key, index) => {
    const cellNumberType = ["%", "dni", "€", areaFormat(), areaFormat(), areaFormat(), areaFormat()];

    if(key === "time_to_lease") {
      return handleStringTranslation("days");
    }
    return cellNumberType?.[index];
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageHeader} fixed render={({ pageNumber }) => (
          pageNumber === 1 ? null : (
            <>
              <Image
                style={styles.pageHeaderImage}
                src={{
                  uri: `${buildingImage}`,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: ""
                }}
              />
              <View style={styles.pageHeaderContent}>
                <Text style={styles.pageHeaderBuildingName}>{buildingName}</Text>
                <Text style={styles.pageHeaderAddress}>{buildingAddress}</Text>
              </View>
            </>
          )
        )} />

        {/* Building Name */}
        <View style={styles.buildingNameContainer}>
          <Text style={styles.buildingName}>{buildingName}</Text>
          <Text style={styles.address}>{buildingAddress}</Text>
        </View>

        {/* First page content */}
        <View style={styles.headerSection}>
          <View style={styles.buildingInfoColumn}>
            <View style={styles.buildingInfoRow}>
              <Image
                style={styles.buildingImage}
                src={{
                  uri: `${buildingImage}`,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: ""
                }}
              />
              <View style={styles.buildingInfo}>
                <View style={styles.headerSectionTable}>
                  {[
                    [handleStringTranslation("VOCABULARY.Type"), buildingTypeRender],
                    [handleStringTranslation("VOCABULARY.Class"), buildingClass],
                    [handleStringTranslation("VOCABULARY.Completion year"), completionYear],
                    [handleStringTranslation("VOCABULARY.Gross area"), grossArea],
                    [handleStringTranslation("VOCABULARY.Net area"), netArea],
                  ].map(([label, value]) => (
                    <View style={styles.headerSectionTableRow} key={label}>
                      <View style={styles.headerSectionTableCol}>
                        <Text style={styles.headerSectionTableCell}>{label}:</Text>
                      </View>
                      <View style={styles.headerSectionTableCol}>
                        <Text style={styles.headerSectionTableCellRight}>{value}</Text>
                      </View>
                    </View>
                  ))}
                </View>
                <Text style={styles.reportDate}>
                  {handleStringTranslation("VOCABULARY.Report generated on")} <Text style={styles.reportDateBold}>{toDateFormat(updatedAt)}</Text>
                </Text>
              </View>
            </View>
          </View>
          {(ownerName || (isContactDataEnable && leasingCompanyName)) && (
            <View style={styles.ownerColumn}>
              {ownerName &&
              <View style={styles.ownerBox}>
                <View style={styles.ownerInfo}>
                  <Text style={styles.ownerLabel}>{handleStringTranslation("VOCABULARY.Owner")}</Text>
                  <Text style={styles.ownerName}>{ownerName}</Text>
                </View>
                {ownerLogo && (
                  <View style={styles.ownerLogoContainer}>
                    <Image style={styles.ownerLogo} src={`${ownerLogo}`} />
                  </View>
                )}
              </View>
              }
              {isContactDataEnable && leasingCompanyName && (
              <View style={styles.ownerBox}>
                <View style={styles.ownerInfo}>
                  <Text style={styles.ownerLabel}>{handleStringTranslation("VOCABULARY.Leasing Manager")}</Text>
                  {leasingPersonPhone && <Text style={styles.ownerName}>{leasingPersonPhone}</Text>}
                  {leasingPersonEmail && <Text style={styles.ownerName}>{leasingPersonEmail}</Text>}
                </View>
              </View>
              )}
            </View>
          )}
        </View>

        {/* Leased Terms Section */}
        <View style={styles.leasedTermsSection}>
          <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Lease terms")}</Text>
          <View style={styles.tablesContainer}>
            {/* First Table */}
            <View style={styles.leasedTermsTable}>
              {[
                [handleStringTranslation("VOCABULARY.Rent - office"), rentOffice],
                [handleStringTranslation("VOCABULARY.Rent - retail"), rentRetail],
                [handleStringTranslation("VOCABULARY.Service charge"), serviceCharge],
                [handleStringTranslation("VOCABULARY.Lease period"), leasePeriod],
              ].map(([label, value]) => (
                <View style={styles.leasedTermsTableRow} key={label}>
                  <View style={styles.leasedTermsTableCol}>
                    <Text style={styles.leasedTermsTableCell}>{label}</Text>
                  </View>
                  <View style={styles.leasedTermsTableCol}>
                    <Text style={styles.leasedTermsTableCellRight}>{value}</Text>
                  </View>
                </View>
              ))}
            </View>

            {/* Second Table */}
            <View style={styles.leasedTermsTable}>
              {[
                [handleStringTranslation("VOCABULARY.Common area factor"), commonAreaFactor],
                [handleStringTranslation("VOCABULARY.Parking factor"), parkingFactor],
                [handleStringTranslation("VOCABULARY.Parking ground"), parkingGround],
                [handleStringTranslation("VOCABULARY.Parking underground"), parkingUnderground],
              ].map(([label, value]) => (
                <View style={styles.leasedTermsTableRow} key={label}>
                  <View style={styles.leasedTermsTableCol}>
                    <Text style={styles.leasedTermsTableCell}>{label}</Text>
                  </View>
                  <View style={styles.leasedTermsTableCol}>
                    <Text style={styles.leasedTermsTableCellRight}>{formatCurrency(value)}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Competitors Comparison Section */}
        {isComparisonEnable && competitors?.length > 0 && (
        <View style={styles.fullWidthSection} wrap={false}>
          <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Comparable properties")}</Text>
          <View style={styles.comparisonContainer}>
            <View style={[styles.comparisonRow, styles.comparisonHeaderRow]}>
              <Text style={styles.comparisonHeaderCell}></Text>
              <Text style={styles.comparisonHeaderCellRight}>{handleStringTranslation("VOCABULARY.Availability")}</Text>
              <Text style={styles.comparisonHeaderCellMiddleRight}>{handleStringTranslation("VOCABULARY.6M Take-up")}</Text>
              <Text style={styles.comparisonHeaderCellMiddleRight}>{handleStringTranslation("VOCABULARY.6M New Vacancy")}</Text>
              <Text style={styles.comparisonHeaderCellMiddleRight}>{handleStringTranslation("VOCABULARY.Asking rent")}</Text>
              <Text style={styles.comparisonHeaderCellRight}>{handleStringTranslation("VOCABULARY.Time to lease")}</Text>
            </View>
            {competitors?.map((competitor, index) => (
              <View 
                key={index} 
                style={[
                  styles.comparisonRow, 
                  index === 0 && { borderBottomColor: '#A2A5B8', borderBottomWidth: 1 }
                ]}
              >
                <View style={styles.comparisonCell}>
                  <Text style={[
                    { fontWeight: 'bold' }, 
                    index !== 0 && { color: '#EC5638' }
                  ]}>
                    {competitor?.name}
                  </Text>
                </View>
                <Text style={[
                  styles.comparisonCellRight, 
                  index === 0 && { fontWeight: 'bold' }
                ]}>
                  {competitor?.areaAvailable ? toNumberFormat(competitor?.areaAvailable) + " m²" : "0 m²"}
                </Text>
                <Text style={[
                  styles.comparisonCellMiddleRight, 
                  index === 0 && { fontWeight: 'bold' }
                ]}>
                  {competitor?.transactions6m ? toNumberFormat(competitor?.transactions6m) + " m²" : "0 m²"}
                </Text>
                <Text style={[
                  styles.comparisonCellMiddleRight, 
                  index === 0 && { fontWeight: 'bold' }
                ]}>
                  {competitor?.supplyBoost6m ? toNumberFormat(competitor?.supplyBoost6m) + " m²" : "0 m²"}
                </Text>
                <Text style={[
                  styles.comparisonCellMiddleRight, 
                  index === 0 && { fontWeight: 'bold' }
                ]}>
                  {competitor?.rent ? toNumberFormat(competitor?.rent) + " €" : "-"}
                </Text>
                <Text style={[
                  styles.comparisonCellRight, 
                  index === 0 && { fontWeight: 'bold' }
                ]}>
                  {competitor?.timeToLease ? handleStringTranslation("BUILDING.PHRASE.REDD_INDEX_DAYS", {days: Number(competitor.timeToLease?.toFixed(0)) }) : "-"}
                </Text>
              </View>
            ))}
          </View>
        </View>
        )}

        {/* Market Comparison Section */}
        {isComparisonEnable && visibleMarketComparison?.length > 0 && (
        <View style={styles.fullWidthSection} wrap={false}>
          <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Market comparison")}</Text>
          <View style={styles.comparisonContainer}>
            <View style={[styles.marketComparisonRow, styles.comparisonHeaderRow]}>
              <Text style={styles.comparisonHeaderCell}></Text>
              <Text style={[styles.comparisonHeaderCellRight, { fontWeight: 'bold' }]}>{handleStringTranslation("VOCABULARY.Active building")}</Text>
              <Text style={styles.comparisonHeaderCellMiddleRight}>{handleStringTranslation("VOCABULARY.TOP10 competitors")}</Text>
              <Text style={styles.comparisonHeaderCellRight}>{basic?.addressCity} - {handleStringTranslation("VOCABULARY.Class")} {basic?.buildingClass}</Text>
            </View>
            {visibleMarketComparison?.filter(item => 
              !item?.key?.startsWith("marketplace_") && !item?.key?.endsWith("_6m") && !item?.key?.endsWith("_24m")
            )?.map((item, index) => {
                if(!basic?.isEditable && item?.key?.startsWith("marketplace_")) {
                  return null;
                }
                return(
                  <View key={index} style={[styles.marketComparisonRow]}>
                    <Text style={styles.comparisonCellFirstColumn}>{handleCellKey(item?.key)}</Text>
                    <Text style={styles.comparisonCellRightBold}>
                      {handleCellValue(item?.obj, index, item?.key)}
                      {" "}
                      {handleCellSuffixValue(item?.key, index)}
                    </Text>
                    <Text style={styles.comparisonCellMiddleRight}>
                      {handleCellValue(item?.competitors, index, item?.key)}
                      {" "}
                      {handleCellSuffixValue(item?.key, index)}
                    </Text>
                    <Text style={styles.comparisonCellRight}>
                      {handleCellValue(item?.marketWithClass, index, item?.key)}
                      {" "}
                      {handleCellSuffixValue(item?.key, index)}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>
        )}

        {/* Available Units Section */}
        {availableUnits?.length > 0 && (
        <View style={styles.availableUnitsSection}>
          <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Available units")}</Text>
          
          {/* Table Header */}
          <View style={[styles.unitRow, styles.unitHeaderRow]}>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Type")}</Text>
            <Text style={styles.unitHeaderCellRight}>{handleStringTranslation("VOCABULARY.Area")}</Text>
            <Text style={styles.unitHeaderCellRight}>{handleStringTranslation("VOCABULARY.Floor")}</Text>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Status")}</Text>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Availability")}</Text>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Updated at")}</Text>
          </View>

          {/* Table Rows */}
          {availableUnits.map((unit, index) => {
            const unitArea = unit?.area ? toNumberFormat(unit?.area) + " m²" : "0 m²";
            // status
            const status = FILTERS_SELECTS.UNITS_STATUSES?.filter(option => option?.["id"] === unit?.status);
            const statusLabel = status?.length > 0 ? status?.[0]?.label?.props?.id : null;
            // type
            const unitType = unit?.unitType;
            const type = unitType === UNIT_TYPES.RETAIL ? handleStringTranslation("GENERAL.PHRASE.RETAIL") :
                            unitType === UNIT_TYPES.OFFICE_RETAIL ? handleStringTranslation("GENERAL.PHRASE.OFFICE_RETAIL") :
                              handleStringTranslation("GENERAL.PHRASE.OFFICE");
            // floor
            const floorFinal = isNaN(unit?.floor) ? "-" : unit?.floor;

            return (
            <View key={index} style={styles.unitRow} wrap={false}>
              <Text style={styles.unitCell}>{type}</Text>
              <Text style={styles.unitCellRight}>{unitArea}</Text>
              <Text style={styles.unitCellRight}>{floorFinal}</Text>
              <Text style={styles.unitCell}>{statusLabel ? handleStringTranslation(statusLabel) : "-"}</Text>
              <Text style={styles.unitCell}>{unit?.availableFrom ? toDateFormat(unit?.availableFrom) : "-"}</Text>
              <Text style={styles.unitCell}>{toDateFormat(unit?.updatedAt)}</Text>
            </View>
            )})}
        </View>
        )}

        {/* Amenities and Certificates Sections */}        
        <View style={styles.sectionsContainer} wrap={false}>
          {/* Amenities Section */}
          {isAmenitiesActive && (
          <View style={[
            styles.sectionAmenities,
            isCertificatesActive() ? undefined : styles.sectionAmenitiesFullWidth
          ]}>
            <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Amenities")}</Text>
            <View style={styles.amenitiesContainer}>
              {Object.keys(amenities)?.map((amenity, index) => {
                  const isActive = amenities?.[amenity];

                  if(isActive) {
                    return(
                      <View key={index} style={styles.amenityItem}>
                        <Text>{handleStringTranslation(snakeToCamelCase(amenity))}</Text>
                      </View>
                    );
                  }
                  return null;
                })}
            </View>
          </View>
          )}

          {/* Certificates Section */}
          {isCertificatesActive() && (
          <View style={styles.sectionCertificates}>
            <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Certificates")}</Text>
            <View style={styles.certificatesContainer}>
              {renderCertificates()}
            </View>
          </View>
          )}
        </View>

        {/* Footer */}
        <View style={styles.footer} fixed>
          <Text style={styles.footerText}>{handleStringTranslation("VOCABULARY.Report generated using")}</Text>
          <Image
            style={styles.footerLogo}
            src="/media/branding/redd-logo-neon-200px.png"
          />
        </View>
      </Page>
    </Document>
  );
};

export default PropertyPdfDocument;

