import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import SelectSearch from "app/partials/components/SelectSearch";
import {fetchAllUsers, loginAsUser} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../LoadingScreen";
import BadgeLabel from "../BadgeLabel";

class LoginAsUser extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            loggedInAs: props.user?.data.pk
        };
        props.fetchAllUsers();
    }

    handleCloseModal = () => {
        this.child.current.handleClose();
    };
    handleAutocompleteChange = (target) => {
        this.setState({
            [target.name]: target.value,
        });
    };
    handleSave = () => {
        const {loginAsUser} = this.props;
        const {loggedInAs} = this.state;

        loginAsUser(loggedInAs);
        this.handleCloseModal();
    };

    render() {
        const {allUsers, loadingAllUsers} = this.props;
        const {loggedInAs} = this.state;

        return (
            <ModalCustom
                ref={this.child}
                btn={
                    <div className="kt-notification__item" style={{cursor: "pointer"}}>
                        <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title">
                                <FormattedMessage id="GENERAL.PHRASE.LOGIN_AS_USER"/>
                            </div>
                        </div>
                        <BadgeLabel label="AO" type="admin"/>
                    </div>
                }
                title={<FormattedMessage id="GENERAL.PHRASE.LOGIN_AS_USER"/>}
                handleSave={this.handleSave}
            >
                {loadingAllUsers ? <LoadingScreen/> :
                <SelectSearch
                    name="loggedInAs"
                    value={loggedInAs}
                    label={<FormattedMessage id="GENERAL.PHRASE.SELECT_LOGIN_AS_USER"/>}
                    options={allUsers !== null && allUsers}
                    onChange={this.handleAutocompleteChange}
                    optionLabel="email"
                    optionValue="pk"
                    width={250}
                />
                }
            </ModalCustom>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    loadingAllUsers: state.user?.loadingAllUsers,
    allUsers: state.user?.allUsers,
    loggingAsUser: state.user?.loggingAsUser
});
const mapDispatchToProps = {
    fetchAllUsers: fetchAllUsers,
    loginAsUser: (id) => loginAsUser(id)
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LoginAsUser)
);