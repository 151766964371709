import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Grid} from "@material-ui/core";
import PropertyUnits from "./components/content/PropertyUnits";
import PropertyLeaseTerms from "./components/content/PropertyLeaseTerms";
import PropertyAmenities from "./components/content/PropertyAmenities";
import PropertyChangesTracker from "./components/content/PropertyChangesTracker";
import PropertySimilarProperties from "app/pages/common/components/PropertyPage/PropertySimilarProperties";
import PropertyHeader from "./components/PropertyHeader";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import PropertyContact from "../../common/components/PropertyPage/PropertyContact";
import PropertyCertificates from "../../common/components/PropertyPage/PropertyCertificates";
import PropertyCompetitionCompare from "./components/content/PropertyCompetitionCompare";
import PropertyMarketComparison from "./components/content/PropertyMarketComparison";
import {FormattedMessage} from "react-intl";
import IsAccountType from "../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../constants/ACCOUNT_TYPE";

const breakPointValue = 1054; // Content Container width, NOT window width
const breakPointValueSummary = 1365; // Content Container width, NOT window width
const breakPointValueLogotypes = 1300; // Content Container width, NOT window width
export default class PropertyPage extends React.Component {
  constructor(props) {
    super(props);
    const contentRoot = document.getElementById("kt_content")?.offsetWidth;
    const breakPointState = contentRoot < breakPointValue;
    const breakPointSummaryState = contentRoot < breakPointValueSummary;
    const breakPointLogotypesState = contentRoot < breakPointValueLogotypes;

    this.state = {
      breakPoint: breakPointState || false,
      breakPointSummary: breakPointSummaryState || false,
      breakPointLogotypes: breakPointLogotypesState || false
    }

    this.props.resetOfficeBuildingState && this.props.resetOfficeBuildingState();
  }
  componentDidMount() {
    const body = document.getElementById("root");
    setTimeout(() => {
      body.scrollTo({
        top: 0
      });
    }, 100);
    //
    const contentRoot = document.getElementById("kt_content");
    const observer = new ResizeObserver(entries => {
      const e = entries[0]; // should be only one
      const width = e.contentRect.width;
      const breakPointState = width < breakPointValue;
      const breakPointSummaryState = width < breakPointValueSummary;
      const breakPointLogotypesState = width < breakPointValueLogotypes;
      //
      if(this.state.breakPoint !== breakPointState) {
        this.setState({
          breakPoint: breakPointState
        });
      }
      //
      if(this.state.breakPointSummary !== breakPointSummaryState) {
        this.setState({
          breakPointSummary: breakPointSummaryState
        });
      }
      //
      if(this.state.breakPointLogotypes !== breakPointLogotypesState) {
        this.setState({
          breakPointLogotypes: breakPointLogotypesState
        });
      }
    })
    // start listening for size changes
    observer.observe(contentRoot);
  }

  render() {
    if(this.props.resetting) {
      return <LoadingScreen/>;
    }
    const {breakPoint, breakPointSummary, breakPointLogotypes} = this.state;
    const isUserAgent = IsAccountType({expectedAccountType: ACCOUNT_TYPE.AGENT});
    const isUserAdmin = IsAccountType({expectedAccountType: ACCOUNT_TYPE.ADMIN});

    // Columns content ORDER based on Account Type
    let leftColumnOrder = ["header", "competitionCompare", "marketComparison", "units", "changes", "similar"];
    let rightColumnOrder = ["contact", "leaseTerms", "certificates", "amenities"];
    let mergedColumnOrder = ["header", "contact", "competitionCompare", "marketComparison", "units", "changes", "leaseTerms", "certificates", "amenities", "similar"];
    if(isUserAgent && !isUserAdmin) {
      leftColumnOrder = ["header", "competitionCompare", "units", "changes", "similar"];
      mergedColumnOrder = ["header", "contact", "competitionCompare", "units", "leaseTerms", "certificates", "amenities", "changes", "similar"];
    }
    // Columns content
    const leftColumnContent = [
      {
        key: "header",
        section: (
          <Grid item xs={12}>
            <PropertyHeader
              breakPoint={breakPoint}
              breakPointSummary={breakPointSummary}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "units",
        section: (
          <Grid item xs={12}>
            <PropertyUnits
              breakPointSummary={breakPointSummary}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "changes",
        section: (
          <Grid item xs={12}>
            <PropertyChangesTracker
              initialLimit={5}
              limitBtnLabel={<FormattedMessage id="VOCABULARY.Show all"/>}
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "similar",
        section: (
          <Grid item xs={12}>
            <PropertySimilarProperties {...this.props}/>
          </Grid>
        )
      },
      {
        key: "competitionCompare",
        section: (
          <Grid item xs={12}>
            <PropertyCompetitionCompare {...this.props}/>
          </Grid>
        )
      },
      {
        key: "marketComparison",
        section: (
          <Grid item xs={12}>
            <PropertyMarketComparison {...this.props}/>
          </Grid>
        )
      }
    ];
    const rightColumnContent = [
      {
        key: "leaseTerms",
        section: (
          <Grid item xs={breakPoint ? 6 : 12}>
            <PropertyLeaseTerms {...this.props}/>
          </Grid>
        )
      },
      {
        key: "amenities",
        section: (
          <PropertyAmenities {...this.props}/>
        )
      },
      {
        key: "contact",
        section: (
          <Grid item xs={12}>
            <PropertyContact
              oneLine={breakPoint}
              semiBreak={breakPointSummary}
              logoBreak={breakPointLogotypes}
            />
          </Grid>
        )
      },
      {
        key: "certificates",
        section: (
          <PropertyCertificates breakPoint={breakPoint} {...this.props}/>
        )
      }
    ];
    const mergedColumnContent = leftColumnContent.concat(rightColumnContent);
    const leftColumnFinalOrder = leftColumnOrder.map(orderItem=> leftColumnContent.find(contentItem => contentItem?.key === orderItem));
    const rightColumnFinalOrder = rightColumnOrder.map(orderItem=> rightColumnContent.find(contentItem => contentItem?.key === orderItem));
    const mergedColumnFinalOrder = mergedColumnOrder.map(orderItem=> mergedColumnContent.find(contentItem => contentItem?.key === orderItem));

    if(breakPoint) {
      return(
        <Grid container>
          <Grid item xs={12}>
            <Portlet
              shadowDisabled
            >
              <PortletBody>
                <Grid container spacing={5}>
                  {mergedColumnFinalOrder?.map((item,index) => {
                    return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                  })}
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      );
    }
    return(
      <Grid container>
        <Grid item xs={9}>
          <Portlet
            shadowDisabled
          >
            <PortletBody>
              <Grid container spacing={5}>
                {leftColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
        <Grid item xs={3}>
          <Portlet
            fluidHeight
            shadowDisabled
            style={{backgroundColor: "rgba(250, 250, 250, 0.8)"}}
          >
            <PortletBody>
              <Grid container spacing={5}>
                {rightColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}