import React from "react";
import clsx from "clsx";
import styles from "app/partials/components/PartialComponents.module.scss";
import ModalCustom from "app/partials/components/ModalCustom";
import {addUnitToExistingShortlist, addUnitToNewShortlist, fetchOffersAll} from "app/crud/offers.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputIcon from "app/partials/components/InputIcon";
import LoadingScreen from "../LoadingScreen";
import {Checkbox, FormControl, FormControlLabel, Icon, MenuItem, Select} from "@material-ui/core";
import {ReactComponent as IconAdd} from "app/media/icons/add-offer.svg";
import {callReddClickEvent} from "app/crud/user.crud";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";
import IsPro from "../../../utils/IsPro";
import PaywallModal from "../PayWall/components/PaywallModal";

class UnitAddToOffer extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      shortlistName: "",
      selected: "empty",
      tab: 0,
      forTesting: false
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.offerUpdated &&
      this.props.offerUpdated &&
      this.props.onComplete
    ) {
      this.props.onComplete([]);
    }
  }

  // Handlers
  handleFetchOffers = () => {
    const {fetchOffersAll, callReddClickEvent, propertyID} = this.props;
    fetchOffersAll && fetchOffersAll();

    if(propertyID && callReddClickEvent) {
      callReddClickEvent(
        "office-building-page-add-to-offer-click",
        "office",
        null,
        propertyID
      );
    }
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleSelect = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleChange = (event) => {
    this.setState({
      shortlistName: event.target.value,
    });
  };
  handleChangeTab = newTab => {
    this.setState({
      tab: newTab
    }, () => {
      if(this.state.tab === 1) {
        this.handleFetchOffers();
      }
    });
  }
  handleSave = () => {
    const { unitId, addUnitToNewShortlist, addUnitToExistingShortlist } = this.props;
    const { shortlistName, selected, tab, forTesting } = this.state;
    const units = this.props.units || [unitId];

    // Add to New Offer
    if(tab === 0) {
      if (shortlistName !== "") {
        addUnitToNewShortlist(shortlistName, units, forTesting);
        this.handleCloseModal();
      }
    }
    // Add to Existing Offer
    else if(tab === 1) {
      if (selected !== "" && selected !== "empty") {
        addUnitToExistingShortlist(selected, units);
        this.handleCloseModal();
      }
    }
  };

  render() {
    const { inactive, loadingOffersAll, offersAll, unitId, user, isDummyViewActive } = this.props;
    const { shortlistName, tab, open, selected, forTesting } = this.state;
    const isLoggedInAs = user?.loggedInAs;

    return (
      <IsPro
        paywall
        paywallComponent={
        <PaywallModal
          triggerBtn={
            <ModalTrigger
              color="brandEmpty"
              label={<FormattedMessage id="VOCABULARY.Add to offer" />}
              iconPrefix={<IconAdd/>}
              directBtn
              style={{fontSize: "1.4rem"}}
            />
          }
          triggerButtonVariant={this.props.intl.formatMessage({id: "VOCABULARY.Add to offer"})}
          isDummyViewActive={isDummyViewActive}
        />
        }
        reverseCheck={isDummyViewActive}
        overwriteRule={isDummyViewActive}
      >
        <ModalCustom
          ref={this.child}
          btn={
          <>
          <ModalTrigger
            color="brandEmpty"
            label={<FormattedMessage id="VOCABULARY.Add to offer" />}
            directBtn
            style={{fontSize: "1.4rem"}}
          />
          {/*<button className={styles.btnAddToOfferTrigger}>*/}
          {/*  <span className={styles.addIcon}><IconAdd/></span>*/}
          {/*  <FormattedMessage id="VOCABULARY.Add to offer" />*/}
          {/*</button>*/}
          </>
          }
          btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
          title={<FormattedMessage id="VOCABULARY.Add to offer" />}
          handleSave={this.handleSave}
          inactive={inactive}
          onOpen={() => this.handleFetchOffers()}
        >
          <div className={styles.tabsWrapper}>
            <button
              className={clsx(
                styles.btnTab,
                tab === 0 ? styles.active : undefined
              )}
              onClick={() => this.handleChangeTab(0)}
            >
              <FormattedMessage id="VOCABULARY.Add new offer"/>
            </button>
            <button
              className={clsx(
                styles.btnTab,
                tab === 1 ? styles.active : undefined
              )}
              onClick={() => this.handleChangeTab(1)}
            >
              <FormattedMessage id="VOCABULARY.Add to existing offer"/>
            </button>
          </div>
          <div className={styles.tabsContentWrapper}>
            {tab === 0 &&
              <div>
                <InputIcon
                  icon={<Icon>work_outline</Icon>}
                  id="new_shortlist_name"
                  label={<FormattedMessage id="SHORTLIST.PHRASE.NEW_SHORTLIST_NAME" />}
                  name="new_shortlist_name"
                  value={shortlistName}
                  width="100%"
                  onChange={this.handleChange}
                />
                {isLoggedInAs &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forTesting}
                      id="forTesting"
                      value="forTesting"
                      color="primary"
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={<FormattedMessage id="SHORTLIST.PHRASE.FOR_TESTING"/>}
                  style={{marginTop: "0.5rem"}}
                />
                }
              </div>
            }
            {tab === 1 && (
              loadingOffersAll ? (
                <LoadingScreen />
              ) : (
                <FormControl style={{width: "100%"}}>
                  <Select
                    open={open}
                    onClose={this.handleToggleSelect}
                    onOpen={this.handleToggleSelect}
                    value={selected}
                    defaultValue="empty"
                    onChange={this.handleSelect}
                    inputProps={{
                      name: "existing_shortlist",
                      id: "existing_shortlist_select",
                    }}
                  >
                    <MenuItem value="empty">
                      <em>
                        <FormattedMessage id="SHORTLIST.PHRASE.SELECT_SHORTLIST" />
                      </em>
                    </MenuItem>
                    {offersAll &&
                      offersAll.map((offer, index) => {
                        const units = this.props.units || unitId;
                        const disableOffer =
                          units && Array.isArray(units)
                            ? units?.filter((unit) => offer?.units?.includes(unit))
                                .length > 0
                            : offer?.units && offer?.units?.includes(unitId);
                        return (
                          <MenuItem
                            key={index}
                            value={offer.id}
                            disabled={disableOffer}
                          >
                            {offer.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )
            )}
          </div>
        </ModalCustom>
      </IsPro>
    );
  }
}

const mapStateToProps = (state) => ({
  offerUpdated: state?.shortlists?.offerUpdated,
  shortlists: state.shortlists.items,
  offersAll: state.shortlists.offersAll,
  loadingOffersAll: state.shortlists.loadingOffersAll,
  user: state.user.data
});
const mapDispatchToProps = {
  fetchOffersAll: () => fetchOffersAll(),
  addUnitToNewShortlist: (name, units, forTesting) => addUnitToNewShortlist(name, units, forTesting),
  addUnitToExistingShortlist: (id, units) => addUnitToExistingShortlist(id, units),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(UnitAddToOffer)
);
