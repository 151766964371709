import React from "react";
import {MenuItem, Select, Table, TableBody, TableContainer, Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import TablePaginationCustom from "../TableProperties/TablePaginationCustom";
import TableHeadMUI from "./components/TableHeadMUI";
import store from "../../../store/store";
import {filterByModules} from "../../../crud/estates/filters.crud";
import {setLatestActiveTablePage} from "../../../crud/tablePage.crud";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import LoadingScreen from "../LoadingScreen";
import ButtonSimple from "../ButtonSimple";
import tableCustomStyles from "app/partials/components/TableCustom/index.module.scss";
import cn from "classnames";

class TableMUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      order: "desc",
      orderBy: props?.initialOrderBy,
      rowsPerPage: props?.initialRowsPerPage || props?.itemsPerPage,
      limitActive: props?.initialLimit || false
    }
    this.tableRef = React.createRef();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.latestPage !== this.state.page && this.props.latestPage) {
      this.setState({
        page: this.props.latestPage
      });
    }
  }

  // Handlers
  handleChangePage = (event, newPage) => {
    const {handleFetchData, handleFetchDataCustom, setLatestActiveTablePage, currentApplicationView, source} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    this.setState({
      page: newPage
    }, () => {
      const params = {
        ...store.getState().filters,
        page: newPage,
      };
      const target = isOfficeView ? 'offices' : 'industrial';
      const isSymmco = source === "symmco";

      if(isSymmco) {
        handleFetchData && handleFetchData(params);
      }
      else if(handleFetchDataCustom) {
        handleFetchDataCustom({params});
      }
      else {
        handleFetchData && handleFetchData(target, {params});
      }
    });
    setLatestActiveTablePage(newPage);
    document.getElementById("root").scrollTop = 0;
  }
  handleChangeItemsPerPage = (event) => {
    const {filterByModules, filters, handleFetchData, handleFetchDataCustom, currentApplicationView, source} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const newRowsPerPage = event.target.value;

    this.setState({
      rowsPerPage: newRowsPerPage
    }, () => {
      filterByModules({size: newRowsPerPage});
      const params = {
        ...filters,
        size: newRowsPerPage,
      };
      const target = isOfficeView ? 'offices' : 'industrial';
      const isSymmco = source === "symmco";

      if(isSymmco) {
        handleFetchData && handleFetchData(params);
      }
      else if(handleFetchDataCustom) {
        handleFetchDataCustom({params});
      }
      else {
        handleFetchData && handleFetchData(target, {params});
      }

      this.setState({
        page: 1
      });
    });
  }
  handleRequestSort = (columnID, sortOrder) => {
    const {filterByModules, filters, handleFetchData, currentApplicationView, onSort} = this.props;
    const {order, orderBy} = this.state;
    const isDesc = orderBy === columnID && order === "desc";
    const orderVal = isDesc ? "" : "-";
    const orderByVal = (columnID === "updated_at" || columnID === "updated_at_by_user") ? "" : columnID;
    const paramsOrder = {order: orderVal, orderBy: orderByVal};
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    this.setState({
      order: isDesc ? "asc" : "desc",
      orderBy: columnID
    }, () => {
      if(onSort && sortOrder) {
        onSort(columnID, orderVal);
      }
      else {
        filterByModules(paramsOrder);
        const params = {
          ...filters,
          order: orderVal,
          orderBy: orderByVal,
        };
        const target = isOfficeView ? 'offices' : 'industrial';
        handleFetchData && handleFetchData(target, {params});
      }
      this.setState({
        page: 1
      })
    });
  }
  handleSelectAll = (checked) => {
    const {onSelectAll} = this.props;
    if(onSelectAll) {
      onSelectAll(checked);
    }
  }
  handleLimitToggle = () => {
    this.setState({
      limitActive: !this.state.limitActive
    });
  }

  render() {
    const {
      parentRef,
      tHead,
      itemsPerPage,
      totalItems,
      children,
      loading,
      noData,
      noDataMessage,
      containerStyle,
      selected,
      initialLimit,
      limitBtnLabel,
      tableContainerClasses
    } = this.props;
    const {page, order, orderBy, rowsPerPage, limitActive} = this.state;
    const totalPages = Math.ceil(totalItems/itemsPerPage);

    return (
      <div className={styles.tableWrapperContainer}>
        <TableContainer
          ref={parentRef || this.tableRef}
          className={cn(
            styles.tableContainer,
            initialLimit && limitActive ? styles.limitRows : undefined,
            tableContainerClasses ? tableContainerClasses : undefined
          )}
          style={{
            ...containerStyle,
            ...initialLimit && limitActive ? {"--rows-limit": initialLimit} : {}
          }}
        >
          <Table
            size="medium"
            stickyHeader
          >
            {tHead &&
            <TableHeadMUI
              data={tHead}
              order={order}
              orderBy={orderBy}
              selected={selected}
              totalItems={totalItems}
              onRequestSort={this.handleRequestSort}
              onSelectAll={this.handleSelectAll}
              {...this.props}
            />
            }
            <TableBody>
              {children}
            </TableBody>
          </Table>
          {initialLimit && children?.length > initialLimit && limitActive &&
            <div className={tableCustomStyles.tableFooter}>
              <ButtonSimple
                label={limitBtnLabel}
                slim
                onClick={() => this.handleLimitToggle()}
              />
            </div>
          }
        </TableContainer>
        {rowsPerPage && totalPages > 1 && (
          <div className={styles.tableFooter}>
            <div className={styles.rowsPerPageWrapper}>
              <Typography variant="body2">
                <FormattedMessage id="GENERAL.PHRASE.Results per page"/>:
              </Typography>
              <Select
                id="rowsPerPage"
                defaultValue={itemsPerPage}
                value={rowsPerPage}
                onChange={this.handleChangeItemsPerPage}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </div>
            <TablePaginationCustom
              totalPages={totalPages}
              currentPage={page <= 0 ? 1 : page}
              onChangePage={this.handleChangePage}
            />
            <div/>
          </div>
        )}
        {loading &&
        <div className={styles.tableLoadingWrapper}>
          <div className={styles.tableLoader}>
            <LoadingScreen/>
          </div>
        </div>
        }
        {!loading && noData && noDataMessage}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  filters: store.filters,
  latestPage: store.tablePage.latestPage,
  currentApplicationView: store.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  filterByModules: (params) => filterByModules(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableMUI)
);