import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import styles from "./index.module.scss";

export default class UserDropdownMenuItem extends React.Component {
  render() {
    const { link, title, description, icon, badge, hyperlink } = this.props;
    return (
      <>
        {hyperlink ? (
          <a href={hyperlink} target="_blank" rel="noopener noreferrer" className="kt-notification__item">
            {icon && <div className="kt-notification__item-icon">{icon}</div>}
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title">
                <FormattedMessage id={title} />
              </div>
              {description && (
                <div className="kt-notification__item-time">
                  <FormattedMessage id={description} />
                </div>
              )}
              {badge && <div className={styles.badge}>{badge}</div>}
            </div>
          </a>
        ) : (
          <Link to={link} className="kt-notification__item">
            {icon && <div className="kt-notification__item-icon">{icon}</div>}
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title">
                <FormattedMessage id={title} />
              </div>
              {description && (
                <div className="kt-notification__item-time">
                  <FormattedMessage id={description} />
                </div>
              )}
              {badge && <div className={styles.badge}>{badge}</div>}
            </div>
          </Link>
        )}
      </>
    );
  }
}
