import React, {useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import ModalCustom from "../ModalCustom";
import {ReactComponent as IconEdit} from "../../../media/icons/edit.svg";
import EditBody from "./components/EditBody";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import IconColorMUI from "../IconColorMUI";
import {handleEmptyValue, toNumberFormat} from "../../../utils/helpers";
import TooltipCustom from "../TooltipCustom";
import styles from "./index.module.scss";
import cn from "classnames";
import UnitTypePreview from "../_DataParts/UnitTypePreview";
import {IconButton} from "@material-ui/core";

function EditModal({target, triggerDots, triggerCustom, parentData, initialTab, isPropertyEditable, ...props}) {
  const [requestSave, setRequestSave] = useState(false);
  const [requestSaving, setRequestSaving] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const childRef = useRef();
  // Handlers
  const handleRequestSave = () => {
    setRequestSave(true);
    setTimeout(() => {
      setRequestSave(false);
    }, 1000);
  }
  // Helpers
  const renderModalTitle = () => {
    const unitOfficeName = props?.extraData?.officeName;
    const parkName = props?.extraData?.parkName;
    const warehouseName = props?.extraData?.warehouseName;
    const unitWarehouseName = [
      ...parkName ? [parkName] : [],
      ...warehouseName ? [warehouseName] : []
    ];

    if(target === "office") {
      return(
        <>
          <FormattedMessage id="VOCABULARY.Edit building"/>
          {" "}
          <span style={{color: "#ff4626"}}>{parentData?.name}</span>
        </>
      );
    }
    else if(target === "unit") {
      return(
        <>
          <FormattedMessage id="UNITS.PHRASE.UNIT_OPTIONS" />{": "}
          <span style={{color: "#ff4626"}}>{toNumberFormat(parentData?.area, 'area')}</span>
          {" | "}
          <span style={{color: "#ff4626"}}>{handleEmptyValue(parentData?.floor)} <FormattedMessage id="GENERAL.PHRASE.FLOOR_TOLOWER"/></span>
          {unitOfficeName &&
          <span style={{
            display: "block",
            fontSize: "1.3rem",
            fontStyle: "italic",
            marginTop: "1rem"
          }}>{unitOfficeName}</span>
          }
        </>
      );
    }
    else if(target === "park") {
      return(
        <>
          <FormattedMessage id="VOCABULARY.Edit park"/>
          {" "}
          <span style={{color: "#ff4626"}}>{parentData?.name}</span>
        </>
      );
    }
    else if(target === "warehouse") {
      return(
        <>
          <FormattedMessage id="VOCABULARY.Edit warehouse"/>
          {" "}
          <span style={{color: "#ff4626"}}>{parentData?.name}</span>
          {parentData?.parkName && ` | ${parentData?.parkName}`}
        </>
      );
    }
    else if(target === "warehouseUnit") {
      return(
        <>
          <FormattedMessage id="UNITS.PHRASE.UNIT_OPTIONS" />{": "}
          <span style={{color: "#ff4626"}}>{toNumberFormat(parentData?.area, 'area')}</span>
          {" | "}
          <span style={{color: "#ff4626"}}>
            <UnitTypePreview
              industrialType={parentData?.isOffice ? "office" : "warehouse"}
              simple
            />
          </span>
          {unitWarehouseName?.length > 0 &&
          <span style={{
            display: "block",
            fontSize: "1.3rem",
            fontStyle: "italic",
            marginTop: "1rem"
          }}>{unitWarehouseName.join(" - ")}</span>
          }
        </>
      );
    }
    else if(target === "teamMember") {
      return(
        <>
          <FormattedMessage id="TEAM.PHRASE.Manage team member"/>
          {": "}
          <span style={{color: "#ff4626"}}>{props?.data?.user?.email}</span>
        </>
      );
    }
  }

  return(
    <ModalCustom
      ref={childRef}
      btn={
      triggerCustom ? triggerCustom :
      triggerDots ?
        <TooltipCustom title={<FormattedMessage id="UNITS.PHRASE.UNIT_OPTIONS" />} placement="left">
          <IconButton color="inherit">
            <span className={styles.triggerIcon}><IconColorMUI icon="more_vert"/></span>
          </IconButton>
        </TooltipCustom> :
        <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.EDIT"/>} placement={props?.tooltipPlacement || "right"}>
          <IconButton color="inherit">
            <span className={cn(styles.triggerIcon, styles.editIcon)}><IconEdit/></span>
          </IconButton>
        </TooltipCustom>
      }
      fullWidth
      title={renderModalTitle()}
      btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
      handleSave={!saveDisabled && isPropertyEditable ? handleRequestSave : undefined}
      saving={requestSaving}
    >
      <EditBody
        target={target}
        parentData={parentData}
        initialTab={initialTab}
        isEditable={isPropertyEditable}
        requestSave={requestSave}
        onRequestSaving={setRequestSaving}
        onSaveDisabled={setSaveDisabled}
        {...props}
      />
    </ModalCustom>
  );
}

export default withRouter(
  connect(null, null)(EditModal)
);
