import React from "react";
import {connect} from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import {updateUserMarket} from "app/crud/user.crud";
import clsx from "clsx";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import BreadcrumbsCustom from "../../../app/partials/components/BreadcrumbsCustom";
import SearchProperties from "../../../app/partials/components/SearchProperties";
import REDD_TOWER_DUMMY_DATA from "../../../app/pages/offices/external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";

class Header extends React.Component {
  headerCommonRef = React.createRef();
  headerAttributes = this.props.htmlClassService?.attributes?.header;

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const {
      htmlClassService,
      menuHeaderDisplay,
      user,
      updateUserMarket,
      office,
      owner,
      topBar,
      intl,
      park,
      warehouse
    } = this.props;
    const isDummyViewActive =  user?.dummyViewActive;
    const userHasTeam = user && user.team;
    const topBarFilter = topBar && topBar.length;
    const isTopBarEnable = topBar && topBar.length && topBarFilter && topBarFilter.length;
    const currentLang = intl.locale;
    return (
      <div
        className={clsx(`kt-header kt-grid__item ${this.props.headerClasses} ${isDummyViewActive && "kt-header--fixed"}`, !isDummyViewActive && (!userHasTeam || isTopBarEnable) && styles.freeUserHeader)}
        id="kt_header"
        ref={this.headerCommonRef}
        {...this.headerAttributes}
      >
        {!isDummyViewActive && !userHasTeam ?
        <div className={styles.upgradeToProCTA}>
          <Typography><FormattedMessage id="Note, your account is not assigned to any team. Please contact customer service for more information: support@reddplatform.com; +48 883 441 559.:"/></Typography>
        </div>
        : (isTopBarEnable ?
        <div className={clsx(styles.upgradeToProCTA, styles.info)}>
          {topBarFilter && topBarFilter.length && topBarFilter.map((item, index) => {
            if(item && index === 0) {
              const message = currentLang === 'pl' ? item.messagePl : item.messageEn;
              return <div key={index} dangerouslySetInnerHTML={{__html: message}} className={styles.topBarMessageWrapper}/>;
            }
            return false;
          })}
        </div> : '')
        }
        <AnimateLoading />
        {/* <!-- begin: Header Menu --> */}
        {menuHeaderDisplay && <HMenu htmlClassService={htmlClassService} />}
        {/* <!-- end: Header Menu --> */}
        {/* <!-- begin:: Header Topbar --> */}
        {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
        <div className={styles.headerLeftContainer}>
          {!menuHeaderDisplay &&
            <BreadcrumbsCustom
              office={office}
              owner={owner}
              park={park}
              warehouse={warehouse}
            />
          }
        </div>
        {!isDummyViewActive &&
        <div className={styles.headerMiddleContainer}>
          <SearchProperties/>
        </div>
        }
        <div className={styles.headerRightContainer}>
          <Topbar
            htmlClassService={htmlClassService}
            user={user}
            onMarketChange={updateUserMarket}
          />
        </div>
        {/* <!-- end:: Header Topbar --> */}
      </div>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  headerClasses: builder?.selectors?.getClasses(store, {
    path: "header",
    toString: true
  }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
  user: ownProps?.dummyData ? REDD_TOWER_DUMMY_DATA.user : (store.user.data ? store.user.data : store.authApp.user),
  office: store.officeBuilding.basic,
  owner: store.owners.owner,
  park: store.industrialPark.basic,
  warehouse: store.industrialWarehouse.basic
});
const mapDispatchToProps = dispatch => ({
  updateUserMarket: (userData, redirect) => dispatch(updateUserMarket(userData, redirect))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
