import React, {useEffect, useState} from "react";
import ModalCustom from "../../../../../partials/components/ModalCustom";
import SlickSlider from "../../../../../partials/components/SlickSlider";
import {FormattedMessage, injectIntl} from "react-intl";
import SliderCustom from "../../../../../partials/components/SliderCustom";
import SlickNavigation from "../../../../../partials/components/SlickSlider/components/SlickNavigation";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "../../../../../../_metronic";
import {callReddClickEvent} from "../../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";
import {fetchOfficePhotos} from "../../../../../crud/estates/offices/offices.crud";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import IsAdmin from "../../../../../utils/IsAdmin";
import LeadsManagementAddProperty from "../../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import {BUILDING_STATUS, BUILDING_TYPES} from "../../../../../constants";
import {Tooltip} from "@material-ui/core";
import IconColorMUI from "../../../../../partials/components/IconColorMUI";
import EditModal from "../../../../../partials/components/EditModal";
import SimpleMap from "../../../../../partials/components/SimpleMap";
import PropertyHeaderTitle from "../../../../common/components/PropertyPage/PropertyHeaderTitle";
import {returnOfficeBuildingStatus, toNumberFormat, toTimeFromDate} from "../../../../../utils/helpers";
import cn from "classnames";
import REDD_TOWER_DUMMY_DATA from "../../../external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";
import GeneratePropertyPdf from "../pdf/GeneratePropertyPdf";


function PropertyHeaderPreview({...props}) {
  const {basic, basicLoading, photos, photosLoading, fetchOfficePhotos, dummyData} = props;
  const [photosLoaded, setPhotosLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Fetch Data
  useEffect(() => {
    const propertyID = basic?.id;

    if(propertyID && !photosLoaded && !photosLoading && !dummyData) {
      setPhotosLoaded(true);
      fetchOfficePhotos(propertyID);
    }
  }, [basic, photosLoaded, photosLoading, fetchOfficePhotos, dummyData]);

  // Handlers
  const handleValueLoading = (loading, value) => {
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }

  // Variables
  const loading = basicLoading;
  const propertyLocation = [
    ...basic?.addressCity ? [basic?.addressCity] : [],
    ...basic?.addressStreet ? [basic?.addressStreet] : []
  ].join(", ");
  const officeStatus = returnOfficeBuildingStatus(basic?.status);
  const isOfficeEditable = basic?.isEditable;
  const propertyImage = basic?.mainPhotoThumbnail400x400 ? basic?.mainPhotoThumbnail400x400 :
      photos?.length > 0 ? photos?.[0]?.image : toAbsoluteUrl("media/office-card-placeholder.jpg");

  return(
    <div className={cn(
      styles.propertyHeaderPreviewWrapper,
      props?.breakPoint ? styles.singleColumn : undefined
    )}>
      {loading ? <LoadingScreen/> :
      <>
      <div className={styles.propertyHeaderGalleryWrapper}>
        <div className={styles.galleryWrapper}>
          {photosLoading ? <LoadingScreen/> :
          <>
          {photos && photos?.length > 1 ?
            <ModalCustom
              btn={
              <SlickSlider
                slidesToShow={1}
                newSlideRequest={currentSlide}
              >
                {photos?.map((item, index) => {
                  return(
                    <div key={index} className={styles.propertyGalleryPreviewItemWrapper}>
                      <img src={item?.image} alt={`${item?.name}`}/>
                    </div>
                  );
                })}
              </SlickSlider>
              }
              title=""
              btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
              btnFlex
              fullScreen
              inactive={!photos || (photos && photos.length === 0)}
            >
              <SliderCustom
                images={photos?.map(item => item?.image)}
                interval={4000}
              />
            </ModalCustom> : <img src={propertyImage} alt={`${basic?.name}`}/>
          }
          {photos && photos?.length > 1 &&
          <SlickNavigation
            slides={photos}
            currentSlide={currentSlide}
            onSetCurrentSlide={setCurrentSlide}
            style={{
              position: "absolute",
              bottom: "1.2rem",
              right: "1.2rem",
              zIndex: 2
            }}
          />
          }
          </>
          }
        </div>
        <div className={styles.optionsWrapper}>
          {basic?.isEditable &&
          <EditModal
            target="office"
            parentData={basic}
            isPropertyEditable={isOfficeEditable}
            tooltipPlacement="right"
          />
          }
          {!dummyData &&
          <IsAdmin markup="font" markupTooltip="Admin Only">
            <LeadsManagementAddProperty propertyID={basic?.id}/>
          </IsAdmin>
          }
        </div>
      </div>
      <div className={styles.propertyHeaderDataWrapper}>
        <div>
          <PropertyHeaderTitle
            title={basic?.name}
            location={propertyLocation}
            titleActions={
            <>
              {basic?.status !== BUILDING_STATUS.STATUS_EXISTS && officeStatus &&
              <Tooltip
                title={<span style={{fontSize: "1.2rem"}}>{officeStatus}</span>}
                placement="top"
              >
                <span className={styles.propertyStatus}>
                  <IconColorMUI icon="schedule" size="20px"/>
                </span>
              </Tooltip>
              }
            </>
            }
            locationActions={
            <>
              <span>•</span>
              <SimpleMap
                lat={basic?.geoLatitude}
                lng={basic?.geoLongitude}
                propertyID={basic?.id}
                isDummyViewActive={dummyData}
              />
            </>
            }
          />
        </div>
        <table className={styles.propertyHeaderDataTable}>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Type"/>:</td>
              <td>
                {handleValueLoading(
                  basicLoading,
                  basic?.buildingType === BUILDING_TYPES.OFFICE_BUILDING.id ? <FormattedMessage id={BUILDING_TYPES.OFFICE_BUILDING.name}/> :
                  basic?.buildingType === BUILDING_TYPES.APARTMENT_HOUSE.id ? <FormattedMessage id={BUILDING_TYPES.APARTMENT_HOUSE.name}/> :
                  basic?.buildingType === BUILDING_TYPES.WAREHOUSE.id ? <FormattedMessage id={BUILDING_TYPES.WAREHOUSE.name}/> :
                  basic?.buildingType === BUILDING_TYPES.SHOPPING_CENTER.id ? <FormattedMessage id={BUILDING_TYPES.SHOPPING_CENTER.name}/> :
                  basic?.buildingType === BUILDING_TYPES.HQ.id ? <FormattedMessage id={BUILDING_TYPES.HQ.name}/> :
                  basic?.buildingType === BUILDING_TYPES.TENEMENT_HOUSE.id ? <FormattedMessage id={BUILDING_TYPES.TENEMENT_HOUSE.name}/> :
                  basic?.buildingType === BUILDING_TYPES.OTHER.id && <FormattedMessage id={BUILDING_TYPES.OTHER.name}/>
                )}
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Class"/>:</td>
              <td>{basic?.buildingClass}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Completion year"/>:</td>
              <td>{basic?.commissioningYear}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Gross area"/>:</td>
              <td>{toNumberFormat(basic?.totalAreaBrutto, 'area')}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Net area"/>:</td>
              <td>{toNumberFormat(basic?.totalAreaNetto, 'area')}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.propertyLastUpdate}>
          <FormattedMessage id="VOCABULARY.Data update"/>{" "}
          <span className={styles.time}>{toTimeFromDate(basic?.updatedAtByUser)}</span>
        </p>
        <GeneratePropertyPdf isDummyViewActive={dummyData}/>
      </div>
      </>
      }
    </div>
  );
}

const mapStateToProps = (store, ownProps) => ({
  basic: ownProps?.dummyData ? REDD_TOWER_DUMMY_DATA.basic : store.officeBuilding.basic,
  basicLoading: ownProps?.dummyData ? false : store.officeBuilding.basicLoading,
  photos: ownProps?.dummyData ? REDD_TOWER_DUMMY_DATA.photos : store.offices.officePhotos,
  photosLoading: ownProps?.dummyData ? false : store.offices.officePhotosLoading,
  currentApplicationView: store.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchOfficePhotos: (officeId) => fetchOfficePhotos(officeId),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyHeaderPreview)
);