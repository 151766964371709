import React from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import LoadingScreen from "../LoadingScreen";

export default function ButtonSimple({label, icon, loading, onClick, style, ...props}) {
  return(
    <button
      className={cn(
        styles.btn,
        loading ? styles.inactive : undefined,
        props?.slim ? styles.slim : undefined,
        props?.active ? styles.active : undefined,
        props?.clean ? styles.clean : undefined
      )}
      style={style}
      onClick={onClick}
    >
      {loading ?
        <LoadingScreen loaderStyle={{width: "16px", height: "16px", marginRight: "5px"}}/> :
          icon ? icon : null
      }
      {label}
    </button>
  );
}