import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import SelectSearch from "app/partials/components/SelectSearch";
import {fetchVisibleTeams} from "app/crud/user.crud";
import {setTeamAsActive} from "app/crud/team.crud";
import connect from "react-redux/es/connect/connect";
import BadgeLabel from "../BadgeLabel";

class TeamSetAsActive extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      team: props.team
    };
    props.fetchVisibleTeams();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleAutocompleteChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };
  handleSave = () => {
    const {team} = this.state;
    const {setTeamAsActive} = this.props;
    setTeamAsActive(team);
    this.handleCloseModal();
  };

  render() {
    const {visibleTeams} = this.props;
    const {team} = this.state;
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <div className="kt-notification__item" style={{cursor: "pointer"}}>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title">
                <FormattedMessage id="TEAM.PHRASE.TEAM"/>
              </div>
            </div>
            <BadgeLabel label="AO" type="admin"/>
          </div>
        }
        title={<FormattedMessage id="TEAM.PHRASE.CHANGE_TEAM"/>}
        handleSave={this.handleSave}
      >
        <SelectSearch
          name="team"
          value={team}
          label={<FormattedMessage id="TEAM.PHRASE.SELECT_ACTIVE_TEAM"/>}
          options={visibleTeams !== null && visibleTeams}
          onChange={this.handleAutocompleteChange}
          optionLabel="fullName"
          optionValue="id"
          width={250}
        />
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  team: state.user.data.team,
  visibleTeams: state.user.visibleTeams
});
const mapDispatchToProps = {
  setTeamAsActive: teamId => setTeamAsActive(teamId),
  fetchVisibleTeams: fetchVisibleTeams
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamSetAsActive)
);