export default {
  // COMMON ROUTES
  HOME_PAGE: '/',
  DASHBOARD_PAGE: '/dashboard',
  USER_PROFILE: '/profile',
  ACCOUNT_REQUEST_PAGE: '/account-request',
  BECOME_PREMIUM_PAGE: '/become-pro',
  LOGIN_PAGE: '/login',
  RESET_PASSWORD_PAGE: '/reset-password',
  CHANGE_PASSWORD_PAGE: '/change-password',
  SET_USER_PASSWORD: '/set-user-password',
  EDIT_PROFILE_PAGE: '/edit-profile',
  TERMS_OF_SERVICE_PAGE: '/terms-of-service',
  PRIVACY_POLICY_PAGE: '/privacy-policy',
  ABOUT_PAGE: '/about-redd',
  AGENT_REGISTER_PAGE: '/agent-register',
  LANDLORD_REGISTER_PAGE: '/landlord-register',
  COMPETITION_ANALYSIS_PAGE: '/competition-analysis',
  SYMMCO: '/symmco',
  LATEST_TRANSACTIONS: '/latest-transactions',
  SUPPLY_BOOST: '/supply-boost',
  // ESTATES ROUTES
  BUILDINGS_LIST_PAGE: '/buildings',
  MODULES_LIST_PAGE: '/units',
  BUILDING_PAGE: '/building',
  BUILDING_V2_PAGE: '/building-v2',
  COWORKS_PAGE: '/coworks',
  COWORK_PAGE: '/cowork',
  ANALYTICS_PAGE: '/analytics',
  OWNERS_LIST_PAGE: '/investment',
  OWNER_PAGE: '/owner',
  TRANSPARENCY_PAGE: '/transparency',
  ACQUISITIONS_PAGE: '/acquisitions',
  BIURA_INFO_STATS_PAGE: '/biura-info-stats',
  MARKETPLACE_LEADS: '/marketplace-leads',
  LEADS_TENANT_INTRODUCTIONS: "/tenant-introductions",
  LEADS_POTENTIAL_TENANTS: "/potential-tenants",
  PROPERTY_PARTICULARS_ANALYSIS: '/property-particulars-analysis',
  SHORTLISTS_PAGE: '/offers',
  SHORTLISTS_LANDLORD_PAGE: '/offers-analysis',
  OFFERS_ONLINE_ANALYSIS: '/offers-online-analysis',
  PROPERTY_PARTICULARS_ONLINE: '/property-particulars',
  OFFERS_ONLINE: '/offers-online',
  // INDUSTRIAL ROUTES
  INDUSTRIAL_DASHBOARD: '/dashboard-industrial',
  INDUSTRIAL_PARKS: '/industrial-parks',
  INDUSTRIAL_BUILDINGS: '/industrial-buildings',
  INDUSTRIAL_PARK: '/industrial-park',
  INDUSTRIAL_BUILDING: '/industrial-building',
  INDUSTRIAL_SHORTLISTS_PAGE: '/industrial-offers',
  INDUSTRIAL_OFFERS_ONLINE_ANALYSIS: '/industrial-offers-online-analysis',
  INDUSTRIAL_SHORTLISTS_LANDLORD_PAGE: '/industrial-offers-analysis',
  INDUSTRIAL_PROPERTY_PARTICULARS_ONLINE: '/industrial-property-particulars',
  INDUSTRIAL_OFFERS_ONLINE: '/industrial-offers-online',
  INDUSTRIAL_PROPERTY_PARTICULARS_ANALYSIS: '/industrial-property-particulars-analysis',
  INDUSTRIAL_FINNE_STATISTICS: '/finne-leads',
  // SHARED/UNKNOWN ROUTES
  USER_WATCHLIST: '/watchlist',
  TEAM_PAGE: '/team',
  SUBSCRIBERS_PAGE: '/subscribers',
  MEMBERS_PAGE: '/members',
  TEAMS_PAGE: '/teams',
  UNSUBSCRIBE_PAGE: '/subscribers',
  ADD_SUBSCRIBERS_PAGE: '/add-subscribers',
  LEADS_MANAGEMENT: '/leads-management',
  ADMIN_ADD_USER: '/admin-add-user',
  ADMIN_MANAGE_ACCOUNT_REQUESTS: '/admin-manage-account-requests',
  // APP2 FREE ACCESS
  REDD_TOWER_DEMO: "/redd-tower-demo",
  // EXTERNAL ROUTES
  REDD_LANDING_PAGE: "https://www2.reddplatform.com",
  REDD_TOWER_REDIRECT_WWW2: "https://www2.reddplatform.com/#home-contact"
};
